

































import Vue from 'vue'
import { ICard, IOptions } from '../store/state';

interface CardComputedProperties {
	card: ICard,
	translation: {},
	options: IOptions,
    layout: string,
	objectFitCompatible: boolean
    
}

export default Vue.extend<{}, {}, CardComputedProperties, {}>({
	name: 'card',
	computed: {
		card() { return this.$store.getters.card },
		translation() { return this.$store.getters.translation },
		options() { return this.$store.getters.options },
		layout() {
			if(this.options.layout == 'row')
			{
				if(this.options.imagePosition == 'right')
				{
					return "row-reverse"
				}
				return "row";
			}
            if(this.options.layout == '1x1')
			{
				return "1x1";
			}
			return "column";
        },
		objectFitCompatible(){
			return ('objectFit' in document.documentElement.style === true);
		}
	}
})
